import React from 'react';
import styled, { css } from 'styled-components';
import './main.css';

function ButtonsView({ userGameState, checkAnswer }) {
  return (
    <div>
      {userGameState?.guessedCorrect == null ? (
        <>
          <div><SubmitButton id="SubmitButton" onClick={checkAnswer}>Submit</SubmitButton></div>
          {/* Uncomment and adjust the HintButton as needed */}
          {/* <div style={{marginTop:"10px"}}>
            <HintButton id="HintButton" href="sms:?&body=I%20need%20help%20with%20today's%20Portmanteau:%0Aportmanteaugame.com">
              Share today's puzzle for a hint
            </HintButton>
          </div> */}
        </>
      ) : (
        <div style={{
          color: (userGameState?.guessedCorrect ?? false) === true ? portColorGreen : portColorRed,
          paddingTop: "5px",
          textAlign: "right",
          paddingRight: "20px",
        }}>
          {(userGameState?.guessedCorrect ?? false) === true ? "You got it!" : "Better luck tomorrow!"}
        </div>
      )}
    </div>
  );
}

const portColorBeige = css`
	rgb(248, 207, 167)
`

const portColorBlack = css`
	rgb(19, 5, 0)
`

const portColorRed = css`
	rgb(255, 69, 4)
`

const portColorGreen = css`
	rgb(138, 194, 106)
`

const SubmitButton = styled.button`
	width: calc(100% - 20px);
	padding: 10px;
	border: none;
	border-radius: 25px;
	margin-top: 20px;
	font-size: 18px;
	opacity: 0.8;
	font-weight: 600;
	background-color: ${portColorRed};
	color: white;
`

export default ButtonsView;
