import React from 'react';
import styled, {css} from 'styled-components';
import './main.css';
import instagramLogo from './instagramlogo.png';
import linkedinLogo from './linkedinIcon.png';

const portColorBlack = css`
	rgb(19, 5, 0)
`

const portColorGreen = css`
	rgb(138, 194, 106)
`

const portColorRed = css`
	rgb(255, 69, 4)
`

const TeamContainer = styled.div`
  display: flex;
  overflow-x: auto;

  & > * {
    flex: 0 0 auto;
    min-width: 200px;
  }

  @media (min-width: 600px) {
    justify-content: center;
  }
`;

const EmployeeCard = styled.div`
  width: 250px;
  height: 250px;
  overflow: hidden;
  border-radius: 25px;
  margin: 10px;
  background-color: white;
`;

const employees = [{
    name: "Justin Lennox",
    role: "Developer",
    imgSrc: "https://imgur.com/iaLETaG.jpg",
    link: "https://www.instagram.com/justinlennx/"
},
{
    name: "Danny Higgins",
    role: "Cluemaster",
    imgSrc: "https://imgur.com/nfyxIXq.jpg",
    link: "https://www.instagram.com/dannyhig/"
},
{
    name: "Reilly Conroy",
    role: "Cluemaster",
    imgSrc: "https://imgur.com/Z8jWNMi.jpg",
    link: "https://www.instagram.com/reillyconroy/"
},
{
    name: "Camille Faulkner",
    role: "Developer",
    imgSrc: "https://imgur.com/SeJbmGF.jpg",
    link: "https://www.linkedin.com/in/camillefaulkner/"
}
]

const buildEmployeeCard = (employee) => {
    return <div style={{padding: "20px", textAlign: "center" }}>
        <img style={{ borderRadius: "50%", maxWidth: "110px" }} src={employee.imgSrc} />
        <p style={{color: "inherit", fontWeight: "bold", margin: "5px 0px" }}>{employee.name}</p>
        <p style={{color: "rgb(75, 75, 75)", margin: "0px", marginBottom: "20px"}}>{employee.role}</p>
        <a style={{ color: "inherit" }} href={employee.link} target="_blank">
            <img style={{maxWidth: "30px"}} src={employee.name === "Camille Faulkner" ? linkedinLogo : instagramLogo}></img>
        </a>
    </div>
}

function AboutUs() {
    return <div style={{ marginTop: "80px", color: `${portColorBlack}` }}>
        <div style={{marginBottom: "10px"}}>
            <span style={{ fontWeight: "550", fontSize: "24px", color: `${portColorBlack}`, marginRight: "auto" }}>
                The Portman<span style={{color: "rgb(57, 156, 0)"}}>team</span>
            </span>
        </div>
        <TeamContainer>
            {employees.map((employee) => (
                <EmployeeCard key={employee.name}>
                    {buildEmployeeCard(employee)}
                </EmployeeCard>
            ))}
        </TeamContainer>
    </div>
}

export default AboutUs;