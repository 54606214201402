import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAnalytics, logEvent } from "firebase/analytics";
import styled, { css } from 'styled-components';
import { isAnswerAcceptable } from "./stringfunctions";
import './main.css';
import x from "./x.svg";
import shareIcon from "./shareIcon.svg";
import screen1 from "./screen1.png";
import screen2 from "./screen2.png";
import DownloadAppStore from "./DownloadAppStore.svg";
import AnswerView from "./AnswerView";
import ButtonsView from "./ButtonsView";
import AboutUs from './AboutUs';

let activatedFirebase = false;
// window.addEventListener('unhandledrejection', event => {
//     console.error('Unhandled rejection (promise): ', event);
//     // Handle the error, e.g., by showing an error message to the user
//     // It's up to you to decide whether to throw an error. Throwing error in this context does not have the same effect as in Node.js.
//     // In a browser, throwing here won't necessarily cause the application to terminate, but you can use it to log errors or clean up resources.
// });
const GameView = () => {
	// Component state
	const [gameData, setGameData] = useState(null);
	const [userGameState, setUserGameState] = useState(null);
	const [userAnswer, setUserAnswer] = useState('');
	const [errorMessage, setErrorMessage] = useState(null);
	const [analytics, setAnalytics] = useState(null);
	const [hintJustShared, setHintJustShared] = useState(false);

	// var gameID: Int
	// var previousAnswers: [String]?
	// var timeStarted: Double?
	// var backupTimeStarted: Double?
	// var timeToFinish: Double?
	// var guessedCorrect: Bool?
	// var score: Int?
	// var hintsUsed: Int?

	// Fetch game data from Firestore
	useEffect(() => {
		if (typeof document !== "undefined" && !activatedFirebase) {
			activatedFirebase = true;
			// Firebase configuration
			const firebaseConfig = {
				apiKey: "AIzaSyDAYFavReGSpRuAMHyuk6LRON8EQniqoco",
				authDomain: "gamefox-85ba8.firebaseapp.com",
				databaseURL: "https://gamefox-85ba8.firebaseio.com",
				projectId: "gamefox-85ba8",
				storageBucket: "gamefox-85ba8.appspot.com",
				messagingSenderId: "751304236268",
				appId: "1:751304236268:web:1604b015367ada8b9099c9",
				measurementId: "G-JKE65ZB1S5"
			};

			// Initialize Firebase
			const app = initializeApp(firebaseConfig);
			// Create a ReCaptchaEnterpriseProvider instance using your reCAPTCHA Enterprise
			// site key and pass it to initializeAppCheck().
			// window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
			initializeAppCheck(app, {
				provider: new ReCaptchaV3Provider("6Lf8GWYpAAAAAOcgxqDBTDI5zhu_djHhqSLT2UhK"),
				isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
			});
			setAnalytics(getAnalytics(app));
			const db = getFirestore(app);

			// console.log("Fetching game");
			const fetchGameData = async () => {
				const dailyContentDocRef = doc(db, "DailyContent/DailyContent");
				const dailySnapshot = await getDoc(dailyContentDocRef);
				if (dailySnapshot.exists() && dailySnapshot.data() !== undefined) {
					const gameData = dailySnapshot.data();
					setGameData(gameData.portmanteau);
					const savedUserStateData = localStorage.getItem("userState");
					const savedUserState = JSON.parse(savedUserStateData);
					if (savedUserState && savedUserState.gameID === gameData.day) {
						// console.log("Setting from saved user state: ", savedUserState);
						const lastAnswer = savedUserState.previousAnswers?.slice(-1)[0] ?? "";
						setUserAnswer(lastAnswer);
						setUserGameState(savedUserState);
						localStorage.setItem("userState", JSON.stringify(savedUserState));
						checkHints(savedUserState);
					} else {
						const newUserGameState = { gameID: gameData.day }
						setUserGameState(newUserGameState);
						localStorage.setItem("userState", JSON.stringify(newUserGameState));
						localStorage.setItem("hintShared", null);
					}
				} else {
					console.error("Daily snapshot doesn't exist");
				}
			};

			fetchGameData();
		}


		// const handleVisibilityChange = () => {
		// 	checkHints();
		// };

		// document.addEventListener('visibilitychange', handleVisibilityChange);

		// // Cleanup the event listener on component unmount
		// return () => {
		// document.removeEventListener('visibilitychange', handleVisibilityChange);
		// };
	}, []);

	function checkHints(existingUserGameState) {
		// This is your method that you want to call
		const hintShared = localStorage.getItem("hintShared");
		// console.log("Checking hint shared: ", hintShared, "current game state: ", userGameState);
		if (hintShared === "true") {
			localStorage.setItem("hintShared", null);
			let updatedGameState = { ...existingUserGameState };
			updatedGameState.hintsUsed = (updatedGameState.hintsUsed ?? 0) + 1;
			// console.log("Updating user game state with hints to ", updatedGameState);
			setUserGameState(updatedGameState);
			localStorage.setItem("userState", JSON.stringify(updatedGameState));
		}
	}

	// Check the answer
	const checkAnswer = () => {
		// console.log("Checking answer ", userAnswer);
		setErrorMessage(null);
		if (!gameData) {
			setErrorMessage("Can't submit answer, today's game is still loading");
			return
		}
		if (!userGameState) {
			setErrorMessage("Failed to submit answer");
			return
		}
		let updatedGameState = { ...userGameState };
		if (!updatedGameState || updatedGameState?.gameID !== gameData.day) {
			updatedGameState = { gameID: gameData.day };
		}
		// console.log(userAnswer, gameData, gameData.displayAnswer, gameData.otherAnswers);
		const isCorrect = isAnswerAcceptable(userAnswer, gameData.displayAnswer, gameData.otherAnswers);
		if (isCorrect) {
			// console.log("Guessed correct!");
			updatedGameState.guessedCorrect = true;
			logEvent(analytics, 'portWeb_finishCorrect');
		} else {
			// console.log("Guessed incorrect");
			const previousAnswers = updatedGameState.previousAnswers ?? [];
			if (previousAnswers.length >= 2) {
				// console.log("Finished guessing incorrectly");
				logEvent(analytics, 'portWeb_finishWrong');
				previousAnswers.push(userAnswer);
				updatedGameState.guessedCorrect = false;
			} else {
				previousAnswers.push(userAnswer);
			}
			updatedGameState.previousAnswers = previousAnswers;
		}
		// console.log("Updated game state: ", updatedGameState);
		setUserGameState(updatedGameState);
		localStorage.setItem("userState", JSON.stringify(updatedGameState));
	};

	const dateString = () => {
		const baseDate = new Date('2024-01-01'); // Starting point: January 1st, 2024
		const offsetDays = gameData?.day + 1; // This is your offset

		// Check if gameData?.day is defined to avoid errors
		if (typeof offsetDays !== 'undefined') {
			const targetDate = new Date(baseDate.getTime()); // Clone the base date to avoid mutating it
			targetDate.setDate(baseDate.getDate() + offsetDays); // Add the offset to the base date

			// Format the date as desired, for example, "January 5, 2024"
			const options = { year: 'numeric', month: 'long', day: 'numeric' };
			const dateString = targetDate.toLocaleDateString('en-US', options);

			return dateString;
		} else {
			return null;
		}
	}

	function clickOrigin(e) {
		var target = e.target;
		var tag = [];
		tag.tagType = target.tagName.toLowerCase();
		tag.tagClass = target.className.split(' ');
		tag.id = target.id;
		tag.parent = target.parentNode;

		return tag;
	}

	document.body.onclick = function (e) {
		const elem = clickOrigin(e);

		if (elem.tagType === 'a') {
			switch (e.target.id) {
				case "HintButton":
					localStorage.setItem("hintShared", "true");
					logEvent(analytics, "portWeb_hintShared");
					setHintJustShared(true);
					break;
				case "ShareButton":
					logEvent(analytics, "portWeb_shared");
					break;
				case "AppStoreLink":
					logEvent(analytics, "portWeb_appLink");
					break;
				default:
					break;
			}
			return true;
		} else {
			return true;
		}
	};

	function getHintString() {
		const answerString = gameData.hintAnswer || gameData.displayAnswer;
		const hintCount = userGameState.hintsUsed || 0;

		const revealInterval = 4;
		let hintString = "";

		// Convert the answerString into an array to use the index in the loop
		[...answerString].forEach((character, index) => {
			let shouldReveal = false;

			// Check if the current index should be revealed based on the hintCount
			for (let offset = 0; offset < hintCount; offset++) {
				if ((index - offset) % revealInterval === 0) {
					shouldReveal = true;
					break;
				}
			}

			const charString = character + "";
			if (charString.trim() === '') {
				hintString += "\xa0\xa0\xa0\xa0";
			} else if (shouldReveal) {
				hintString += character;
			} else {
				hintString += " _";
			}
		});

		return hintString;
	}

	return (
		<div className="main-container">
			<h1 className="game-title" style={{ marginBottom: "5px" }}>Portmanteau<span style={{ fontSize: "18px" }}>+</span></h1>
			{/* <div style={{ color: "white", textAlign: "left", margin: "0px 0px", padding: "0px 0px" }}>
				<p style={{ margin: "0" }}>[pôrtˈmantō] <em>noun</em></p>
				<p style={{ margin: "0" }}>A phrase made by combining two other words or phrases</p>
			</div> */}
			<GameContainer>
				{!!gameData && (<div><GameDate>{dateString() ?? "Today's Game"}</GameDate>
					<ClueContainer>
						<Clue1>{gameData?.clue1 || 'A Sir Mix-a-Lot song that inspired Nicki Minaj'}</Clue1>
						<Plus className="plus">+</Plus>
						<Clue2>{gameData?.clue2 || 'A Robert Zemeckis movie that inspired Rick and Morty'}</Clue2>
					</ClueContainer>
					<Rectangle />
					<AnswerView
						userGameState={userGameState}
						gameData={gameData}
						userAnswer={userAnswer}
						setUserAnswer={setUserAnswer}
						setErrorMessage={setErrorMessage}
					/>
					<div style={{ textAlign: 'right', paddingRight: "20px" }}>
						<StrikeImage src={x} alt={`strike1`} style={{ opacity: (userGameState?.previousAnswers ?? []).length >= 1 ? "1.0" : "0.2" }} />
						<StrikeImage src={x} alt={`strike2`} style={{ opacity: (userGameState?.previousAnswers ?? []).length >= 2 ? "1.0" : "0.2" }} />
						<StrikeImage src={x} alt={`strike3`} style={{ opacity: (userGameState?.previousAnswers ?? []).length >= 3 ? "1.0" : "0.2" }} />
					</div>
					{((userGameState?.hintsUsed ?? 0) > 0) && (
						<div style={{ textAlign: 'right', paddingRight: "20px" }}>
							<HintItem style={{ opacity: (userGameState?.hintsUsed ?? 0) >= 1 ? "1.0" : "0.2" }}>?</HintItem>
							<HintItem style={{ opacity: (userGameState?.hintsUsed ?? 0) >= 2 ? "1.0" : "0.2" }}>?</HintItem>
							<HintItem style={{ opacity: (userGameState?.hintsUsed ?? 0) >= 3 ? "1.0" : "0.2" }}>?</HintItem>
						</div>
					)}
					<ButtonsView
						userGameState={userGameState}
						checkAnswer={checkAnswer}
					/>
					{((userGameState?.hintsUsed ?? 0) > 0) && (
						<div style={{ marginTop: "10px" }}>
							<p id="HintAnswer" style={{ color: "gray" }}>
								{getHintString()}
							</p>
						</div>
					)}
					{((userGameState?.hintsUsed ?? 0) < 3 && userGameState?.guessedCorrect == null) && (
						<div style={{ marginTop: "10px" }}>
							<HintButton id="HintButton"
								href="sms:?&body=Check%20out%20today's%20Portmanteau!%0Ahttps://portmanteaugame.com">
								{hintJustShared ? "Refresh page after sharing" : ((userGameState?.hintsUsed ?? 0) <= 0) ? "Get a hint" : "Get another hint"}
							</HintButton>
						</div>
					)}
					<div style={{ textAlign: "trailing", marginLeft: "auto", width: "30px", height: "30px" }}>
						<a href="sms:?&body=Check%20out%20today's%20Portmanteau!%0Ahttps://portmanteaugame.com">
							<img id="ShareImage" alt="Share" src={shareIcon} style={{ width: "20px", height: "20px", opacity: "0.6" }} />
						</a>
					</div>

				</div>)}

				{!gameData && <div className="loader"></div>}

				{!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
			</GameContainer>
			<div style={{ maxWidth: "300px", margin: "auto", overflow: "hidden", borderRadius: "25px" }}>
				<a href="https://apps.apple.com/us/app/portmanteau/id6476009593" id="AppStoreLink" style={{ display: "block", textDecoration: "none", color: "inherit" }}>
					<div style={{ backgroundColor: "white", padding: "20px", textAlign: "center" }}>
						<p style={{ fontWeight: "550" }}>
							Get the Portmanteau iOS app to see all past puzzles, daily streaks, and more!
						</p>
						<div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
							<img src={screen1} alt="Screen 1" style={{ width: "150px", height: "150px", bordeRadius: "25px", objectFit: "scale-down" }} />
							<img src={screen2} alt="Screen 2" style={{ width: "150px", height: "150px", bordeRadius: "25px", objectFit: "scale-down" }} />
						</div>
						<img src={DownloadAppStore} alt="Download" style={{ width: "200px", height: "75px", bordeRadius: "25px", objectFit: "scale-down" }} />
					</div>
				</a>
			</div>
			<AboutUs />
			<Footer>Copyright © 2024 Foxtail Games LLC. All rights reserved.</Footer>
		</div>
	);
};

const portColorBeige = css`
	rgb(248, 207, 167)
`

const portColorBlack = css`
	rgb(19, 5, 0)
`

const portColorRed = css`
	rgb(255, 69, 4)
`

const portColorGreen = css`
	rgb(138, 194, 106)
`

const GameContainer = styled.div`
	background-color: white;
	border-radius: 25px;
	padding: 20px;
	margin: 20px auto;
	font-weight: 600;
	max-width: min(90%, 500px);
`

const GameDate = styled.div`
    text-align: left;
	color: ${portColorBeige};
    margin-bottom: 20px;
	font-weight: 600;
	padding: 10px 0 0 10px;
`

const ClueContainer = styled.div`
	font-size: 25pt;
    color: ${portColorBlack};
	text-align: right;
	font-weight: 600;
`;

const Clue1 = styled.div`
	padding: 20px;
`

const Plus = styled.div`
	margin-right: 20px;
`

const Clue2 = styled.div`
	padding: 20px;
	color: red;
`;

const Rectangle = styled.div`
	background-color: ${portColorBlack};
	height: 4px;
	border: none;
	margin: 0px 20px;
	border-radius: 100px;
`;

const HintButton = styled.a`
	background: none;
	padding: 10px;
	border: none;
	border-radius: 25px;
	font-size: 18px;
	font-weight: 650;
	opacity: 0.6;
	text-decoration: none;
	margin-top: 10px;
	color: ${portColorBlack};
`

const StrikeImage = styled.img`
	width: 15px;
	height: 15px;
	padding-left: 8px;
	padding-top: 5px;
`

const HintItem = styled.p`
	color: purple;
	padding-left: 9px;
	font-size: 24px;
	padding-top: 5px;
	font-weight: 700;
	display: inline;
`

const ErrorMessage = styled.div`
	margin-top: 10px;
	color: ${portColorRed};
	font-weight: 600;
`

const Footer = styled.div`
	margin-top: 100px;
	color: white;
	font-weight: 600;
`

export default GameView;