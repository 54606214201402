import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import './main.css';

function AnswerView({ userGameState, gameData, userAnswer, setUserAnswer, setErrorMessage }) {
  // Assuming setErrorMessage is a state setter passed down as a prop

  useEffect(() => {
    // Check for the necessary data and set an error message if it's not present.
    // This effect will run whenever `gameData.displayAnswer` changes.
    if (!gameData.displayAnswer) {
      setErrorMessage("An issue occurred fetching the game data.");
    }
  }, [gameData.displayAnswer, setErrorMessage]);

  // Early return for one condition
  if (userGameState?.guessedCorrect == null) {
    const previousAnswerLength = (userGameState?.previousAnswers ?? []).length;
    const className = previousAnswerLength > 0 ? `wiggle${previousAnswerLength}` : "AnswerInputClass";
    return (
      <textarea
        autoCorrect="off"
        spellCheck="false"
        id="AnswerInput"
        className={className}
        value={userAnswer}
        onChange={(e) => {
          if (e) {
            setUserAnswer(e.target.value)
          }
        }}
        placeholder="Your Answer"
      />
    );
  } 

  // Handling for other condition
  // It's safe to assume gameData.displayAnswer is available because if it's not,
  // the useEffect hook would have set an error message already.
  const splitIndex = gameData?.teauIndex ?? gameData.displayAnswer.length;
  const leftPart = gameData.displayAnswer.substring(0, splitIndex);
  const rightPart = gameData.displayAnswer.substring(splitIndex);
  const guessedCorrect = (userGameState?.guessedCorrect ?? false) === true;
  const rightColor = guessedCorrect ? portColorGreen : portColorRed;
  const className = guessedCorrect ? "AnswerTextContainer" : "wiggle1";

  return (
    <div style={{display: "flex"}}>
      <div
        className={className}
        style={{
          display: 'inline-block',
          textAlign: "right",
          padding: "10px 20px 10px 20px",
          marginLeft: "auto"
        }}>
        {/* Assuming AnswerText is a styled component or similar */}
        <AnswerText style={{ color: portColorBlack }}>{leftPart}</AnswerText>
        <AnswerText style={{ color: rightColor }}>{rightPart}</AnswerText>
      </div>
    </div>
  );
}

const portColorBeige = css`
	rgb(248, 207, 167)
`

const portColorBlack = css`
	rgb(19, 5, 0)
`

const portColorRed = css`
	rgb(255, 69, 4)
`

const portColorGreen = css`
	rgb(138, 194, 106)
`

const AnswerText = styled.span`
	font-size: 25pt;
	text-align: right;
	font-weight: 600;
`

export default AnswerView;
