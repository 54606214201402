// Helper function to replace all non-alphanumeric characters with an empty string and convert to lowercase
function alphanumeric(str) {
	return str.replace(/[^0-9a-z]/gi, '');
}

// Function to check if the given answer is acceptable
export function isAnswerAcceptable(answer, displayAnswer, otherAnswers) {
	// Combine displayAnswer with otherAnswers into a single array
	const acceptableAnswers = [displayAnswer].concat(otherAnswers || []);

	// Check if the answer matches any acceptable answers
	for (const acceptableAnswer of acceptableAnswers) {
		if (stringsEqualIgnoringWhitespaceAndCase(answer, acceptableAnswer)) {
			return true;
		}
	}
	return false;
}

// Function to check if two strings are equal, ignoring whitespace and case
function stringsEqualIgnoringWhitespaceAndCase(str1, str2) {
	const trimmedStr1 = alphanumeric(str1).toLowerCase();
	const trimmedStr2 = alphanumeric(str2).toLowerCase();

	return trimmedStr1 === trimmedStr2;
}