import './App.css';
import GameView from "./main";

function App() {
  return (
    <div className="App">
      <GameView></GameView>
    </div>
  );
}

export default App;
